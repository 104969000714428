import React, { useState } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { v4 as uuidv4 } from 'uuid';

const CLIENT_ID = "7dbb355e-0e6a-4756-a249-ddcd0aea8a93";
const TENANT_ID = "common";
const REDIRECT_URI = window.location.origin;
const API_GATEWAY_ENDPOINT = "https://ylqm1lgeud.execute-api.us-east-1.amazonaws.com/dev/OutlookTokenExchange";

const msalConfig = {
    auth: {
        clientId: CLIENT_ID,
        authority: `https://login.microsoftonline.com/${TENANT_ID}`,
        redirectUri: REDIRECT_URI,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    }
};

const msalInstance = new PublicClientApplication(msalConfig);

const OutlookOAuth = ({ userEmail }) => {
    const { instance } = useMsal();
    const [state] = useState(uuidv4());
    const [loading, setLoading] = useState(false);

    const handleLogin = async () => {
        setLoading(true);

        try {
            const loginResponse = await instance.loginPopup({
                scopes: ["User.Read", "Mail.ReadWrite", "Mail.Send", "offline_access"],
                state: state,
                prompt: "select_account",
            });

            console.log("Login Successful:", loginResponse);

            // Get the ID token from the login response
            const idToken = loginResponse.idToken;  // Use the idToken instead of code

            if (!idToken) {
                console.error("No ID token found in the response.");
                setLoading(false);
                return;
            }

            // Send the ID token to your AWS Lambda function via API Gateway
            const response = await fetch(API_GATEWAY_ENDPOINT, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id_token: idToken,
                    redirect_uri: REDIRECT_URI,
                    email: userEmail,
                })
            });

            if (!response.ok) throw new Error('Lambda API call failed');

            const data = await response.json();
            console.log('Lambda Response:', data);

        } catch (error) {
            console.error("Login Failed:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {loading ? (
                <p className='text-white px-8 py-2 font-medium text-lg bg-red-500 rounded-full'>Authorizing... Please do not refresh.</p>
            ) : (
                <button onClick={handleLogin} style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 'auto',
                    height: '42px',
                    border: '1px solid #dcdcdc',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                    color: '#5f6368',  // Lighter color
                    fontSize: '14px',
                    fontWeight: '500',
                    cursor: 'pointer',
                    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
                    padding: '0 16px',
                    marginLeft: '10px',  // Added margin-left
                }}>
                    <img src="https://img.icons8.com/color/48/000000/microsoft-outlook-2019--v1.png" alt="Outlook logo" style={{
                        width: '18px',
                        height: '18px',
                        marginRight: '9px'
                    }} />
                    Login with Outlook
                </button>
            )}
        </div>
    );
};

const WrappedOutlookOAuth = (props) => (
    <MsalProvider instance={msalInstance}>
        <OutlookOAuth {...props} />
    </MsalProvider>
);

export default WrappedOutlookOAuth;
