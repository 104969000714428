import React, { useEffect, useState } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import { getUserData, getUserAutoApplyData, createAutoApplyProfile } from "../../components/awsAuth";
import DashboardMenu from "../../components/dashboard/dashboardMenu";
import { useAuth0 } from "@auth0/auth0-react";
import SignInSignOutButton from '../../components/signInSignOut/signInSignOutButton';
import { useNavigate } from 'react-router-dom';
import LogoWhite from '../../images/logoWhite.svg'
import { IoAlertCircle } from "react-icons/io5";
import { addUserIndustries, updateInfo } from "../../components/awsAuth";
import { FiCopy, FiPlus, FiX, FiLock } from "react-icons/fi"; // For the copy icon
import OnSuccessPopup from '../../components/OnSuccessPopup.js'
import Logo from "../../images/logo.svg";
import Nav from "../../pages/nav/nav";
import OnUpgradePopup from '../../components/OnUpgradePopup';
import { addUserResume, deletePreviousResume } from './handleUpload';
import { FaLock } from 'react-icons/fa';
import GoogleOAuth from '../approve/GoogleOAuth';
import InfoTooltip from '../../components/InfoTooltip';
import OutlookOAuth from "../approve/OutlookOAuth";

const { S3 } = require('aws-sdk');
const { DynamoDBClient, UpdateItemCommand } = require("@aws-sdk/client-dynamodb");

const awsCredentials = {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
};

export default function Settings(props) {
    const { user, isAuthenticated, isLoading: authLoading } = useAuth0();
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Consolidated loading state
    const location = useLocation();
    const editProfilePage = location.pathname === '/dashboard/profile';
    const navigate = useNavigate();
    const [selectedJobTypes, setSelectedJobTypes] = useState([]);
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [university, setUniversity] = useState('');
    const [year_in_school, setYearInSchool] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showUpgradeMessage, setShowUpgradeMessage] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const { settingName } = useParams();

    //Auto Apply States
    const [phone, setPhone] = useState('');
    const [dob, setDob] = useState('');
    const [userLocation, setUserLocation] = useState('');
    const [major1, setMajor1] = useState('');
    const [major2, setMajor2] = useState('');
    const [minor, setMinor] = useState('');
    const [workExperiences, setWorkExperiences] = useState([{ jobTitle: '', employer: '', location: '', dates: '', responsibilities: '' }]);
    const [projects, setProjects] = useState([{ name: '', title: '', location: '', dates: '', description: '', link: '' }]);
    const [linkedin, setLinkedin] = useState('');
    const [github, setGithub] = useState('');
    const [twitter, setTwitter] = useState('');
    const [portfolio, setPortfolio] = useState('');
    const [applyInternships, setApplyInternships] = useState(false);
    const [applyFullTime, setApplyFullTime] = useState(false);
    const [requireSponsorship, setRequiresSponsorship] = useState(false);

    const [copyStatus, setCopyStatus] = useState({});

    const dynamoDBClient = new DynamoDBClient({
        credentials: awsCredentials,
        region: awsCredentials.region,
    });
    const s3 = new S3({
        credentials: awsCredentials,
        region: awsCredentials.region,
    });

    const handleJobTypeSelect = (jobType) => {
        setSelectedJobTypes(prevSelectedJobTypes => {
            if (prevSelectedJobTypes.includes(jobType)) {
                return prevSelectedJobTypes.filter(item => item !== jobType);
            } else {
                return [...prevSelectedJobTypes, jobType];
            }
        });
        setUnsavedChanges(true);
    };

    const addWorkExperience = () => {
        setWorkExperiences([...workExperiences, { jobTitle: '', employer: '', location: '', dates: '', responsibilities: '' }]);
    };

    const addProject = () => {
        setProjects([...projects, { name: '', title: '', location: '', dates: '', description: '', link: '' }]);
    };

    const removeWorkExperience = (index) => {
        setWorkExperiences(workExperiences.filter((_, i) => i !== index));
    };

    const removeProject = (index) => {
        setProjects(projects.filter((_, i) => i !== index));
    };

    const handleWorkExperienceChange = (index, field, value) => {
        const newWorkExperiences = [...workExperiences];
        newWorkExperiences[index][field] = value;
        setWorkExperiences(newWorkExperiences);
    };

    const handleProjectChange = (index, field, value) => {
        const newProjects = [...projects];
        newProjects[index][field] = value;
        setProjects(newProjects);
    };

    const copyToClipboard = async (text, variableKey) => {
        try {
            await navigator.clipboard.writeText(text);
            setCopyStatus(prevStatus => ({ ...prevStatus, [variableKey]: true }));

            setTimeout(() => {
                setCopyStatus(prevStatus => ({ ...prevStatus, [variableKey]: false }));
            }, 1000);
        } catch (err) {
            console.error('Failed to copy!', err);
        }
    };

    const variables = [
        { key: '[their_first_name]', description: "Recipient's first name" },
        { key: '[relevant_industry]', description: "Recipient industry (i.e. investment banking)" },
        { key: '[their_company]', description: "Recipient's company (i.e. Goldman Sachs)" },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (isAuthenticated && user) {
                    const userData = await getUserData(user.email);
                    const industries = userData.industry;
                    setSelectedJobTypes(industries);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchData();
    }, [isAuthenticated, user]);


    const submitToDB = () => {
        addUserIndustries(user.email, 0, selectedJobTypes).then(() => {
            console.log("Success");
            setShowSuccessMessage(true);
            setUnsavedChanges(false);
            setTimeout(() => setShowSuccessMessage(false), 3000);
        }).catch(error => console.error("Error adding user industries:", error));
    };

    const [userParagraphCoffeeChat, setUserParagraphCoffeeChat] = useState('');
    const [userSubjectLine, setUserSubjectLine] = useState('');
    const [userParagraphNetwork, setUserParagraphNetwork] = useState('');
    const [resumeFile, setResumeFile] = useState(null);
    const [resumeFilename, setResumeFilename] = useState('');

    const extractFilenameFromS3Uri = (s3Uri) => {
        if (!s3Uri) return '';
        const parts = s3Uri.split('/');
        return parts.pop();
    };

    const viewResume = async () => {
        if (!resumeFilename) {
            alert('No resume uploaded.');
            return;
        }

        const params = {
            Bucket: 'internify-resumes', // Your S3 Bucket name
            Key: `resumes/${resumeFilename}`, // Construct the file key within your bucket
            Expires: 60, // Link expiration time in seconds
        };

        try {
            const url = await s3.getSignedUrlPromise('getObject', params);
            window.open(url, '_blank'); // Open the URL in a new tab
        } catch (error) {
            console.error('Error generating signed URL:', error);
            console.log('Failed to generate resume link.');
        }
    };


    useEffect(() => {
        if (isAuthenticated) {
            getUserData(user?.email)
                .then(data => {
                    setUserData(data); // Assuming data contains the user data you want to use
                    setIsLoading(false); // Update loading state 

                    const filename = extractFilenameFromS3Uri(data?.s3Uri);
                    setResumeFilename(filename);

                    // Check if data.industry does not exist
                    if (!data?.industry) {
                        navigate('/onboard'); // Navigate to the onboard route
                        window.location.reload(); // Refresh the page
                    }
                    setUserParagraphCoffeeChat(data.paragraph_coffee_chat || ''); // Use empty string if not available
                    setUserSubjectLine(data.paragraph_subject_line || ''); // Use empty string if not available
                    setFirstName(data.first_name || ''); // Use empty string if not available
                    setLastName(data.last_name || ''); // Use empty string if not available
                    setUniversity(data.custom_university || ''); // Use empty string if not available
                    setYearInSchool(data.year_in_school || ''); // Use empty string if not available

                    if(data.subscription === "2"){
                        if (data.subscription === "2") {
                            getUserAutoApplyData(user?.email)
                                .then(autoApplyData => {
                                    if (autoApplyData) {
                                        setPhone(autoApplyData.phone_number || '');
                                        setDob(autoApplyData.dob || '');
                                        setUserLocation(autoApplyData.user_location || '');
                                        setMajor1(autoApplyData.major || '');
                                        setMajor2(autoApplyData.major2 || '');
                                        setMinor(autoApplyData.minor || '');
                                        setWorkExperiences(autoApplyData.work_experience || [{ jobTitle: '', employer: '', location: '', dates: '', responsibilities: '' }]);
                                        setProjects(autoApplyData.project_list || [{ name: '', title: '', location: '', dates: '', description: '', link: '' }]);
                                        setLinkedin(autoApplyData.link_linkedin || '');
                                        setGithub(autoApplyData.link_github || '');
                                        setTwitter(autoApplyData.link_twitter || '');
                                        setPortfolio(autoApplyData.link_portfolio || ''); 
                                        setApplyInternships(autoApplyData.apply_internships || false);
                                        setApplyFullTime(autoApplyData.apply_fulltime || false);
                                        setRequiresSponsorship(autoApplyData.requires_sponsorship || false);
                                    } else {
                                        createAutoApplyProfile(user?.email);
                                    }
                                })
                                .catch(err => {
                                    console.error('Error fetching auto apply data:', err);
                                });
                        }
                    }

                })
                .catch(err => {
                    return (
                        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
                            <div className="bg-[#f8fbff] rounded-xl shadow-lg text-gray-800 p-12 relative flex justify-center flex-col items-center" style={{ maxWidth: '500px' }}>
                                Log in to view dashboard
                                <br />
                                <br />
                                <SignInSignOutButton title='Sign in or create account' />
                            </div>
                        </div>
                    );
                });
        } else {
            setIsLoading(authLoading); // Reflect auth loading state
        }
    }, [user?.email, isAuthenticated, authLoading, navigate]);


    if (!isAuthenticated) {
        return (<div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
            <div className="bg-[#f8fbff] rounded-xl shadow-lg text-gray-800 p-12 relative flex justify-center flex-col items-center text-center" style={{ maxWidth: '700px' }}>
                <h1 className='text-3xl mb-4'>Login Error</h1>
                You're currently not signed in or do not have an account.
                <br></br>
                <br></br>
                <SignInSignOutButton title='Sign in or create account'></SignInSignOutButton>
            </div></div>)
    }

    const handleParagraphSubmitCoffeeChat = async (event) => {
        event.preventDefault();

        const params = {
            TableName: "internify",
            Key: {
                client_email: { S: user.email }
            },
            UpdateExpression: "set paragraph_coffee_chat = :p, paragraph_subject_line = :s",
            ExpressionAttributeValues: {
                ":p": { S: userParagraphCoffeeChat },
                ":s": { S: userSubjectLine }
            },
            ReturnValues: "UPDATED_NEW"
        };

        try {
            const command = new UpdateItemCommand(params);
            const response = await dynamoDBClient.send(command);
            console.log("Success", response);
            setShowSuccessMessage(true);
            setUnsavedChanges(false);
            setTimeout(() => setShowSuccessMessage(false), 3000);
        } catch (error) {
            console.error(error);
            console.log("Error");
        }
    };

    const handleParagraphSubmitNetwork = async (event) => {
        event.preventDefault();

        console.log("No paragraph network")
    };

    const handleUpgradeToggle = async (event) => {
        setShowUpgradeMessage(true);
        setTimeout(() => setShowUpgradeMessage(false), 3000);
    }

    const handleResumeSubmit = async (event) => {
        event.preventDefault();
    
        if (resumeFile) {
            const fileName = `resumes/${user.email}-${Date.now()}.pdf`;
    
            const params_s3 = {
                Bucket: 'internify-resumes',
                Key: fileName,
                Body: resumeFile,
                ContentType: 'application/pdf',
            };
    
            try {
                // Delete the previous resume if it exists
                if (resumeFilename) {
                    await deletePreviousResume(`resumes/${resumeFilename}`);
                }
    
                const uploadResponse = await s3.upload(params_s3).promise();
                console.log('Upload Successful', uploadResponse);
                const s3Uri = `s3://${params_s3.Bucket}/${params_s3.Key}`;
    
                // 2. Update user DB entry using email to add s3URI
                const params_dynamoDB = {
                    TableName: 'internify',
                    Key: {
                        client_email: { S: user.email },
                    },
                    UpdateExpression: 'set s3Uri = :s',
                    ExpressionAttributeValues: {
                        ':s': { S: s3Uri },
                    },
                    ReturnValues: 'ALL_NEW',
                };
    
                const command = new UpdateItemCommand(params_dynamoDB);
                const response = await dynamoDBClient.send(command);
                setShowSuccessMessage(true);
                setResumeFilename(extractFilenameFromS3Uri(fileName));
                setUnsavedChanges(false);
                setTimeout(() => setShowSuccessMessage(false), 3000);
            } catch (err) {
                console.error("Error:", err);
                console.log("Error");
            }
    
        }
    };
    
    
    

    const updateBasicInfo = async () => {
        await updateInfo(user.email, 'first_name', first_name)
        await updateInfo(user.email, 'last_name', last_name)
        await updateInfo(user.email, 'university', university)
        await updateInfo(user.email, 'year_in_school', year_in_school)
        setShowSuccessMessage(true);
        setUnsavedChanges(false);
        setTimeout(() => setShowSuccessMessage(false), 3000);
    }

    const handleFileChange = (event) => {
        setResumeFile(event.target.files[0]);
        setUnsavedChanges(true);
    };

    const handleAutoApplySubmit = async (event) => {
        event.preventDefault();
        
        const autoApplyData = {
            phone_number: phone,
            dob: dob,
            user_location: userLocation,
            major: major1,
            major2: major2,
            minor: minor,
            work_experience: workExperiences.map((work) => ({
                M: {
                    jobTitle: { S: work.jobTitle || '' },
                    employer: { S: work.employer || '' },
                    location: { S: work.location || '' },
                    dates: { S: work.dates || '' },
                    responsibilities: { S: work.responsibilities || '' },
                }
            })),
            project_list: projects.map((project) => ({
                M: {
                    name: { S: project.name || '' },
                    title: { S: project.title || '' },
                    location: { S: project.location || '' },
                    dates: { S: project.dates || '' },
                    description: { S: project.description || '' },
                    link: { S: project.link || '' },
                }
            })),
            link_linkedin: linkedin,
            link_github: github,
            link_twitter: twitter,
            link_portfolio: portfolio,
            apply_internships: applyInternships,
            apply_fulltime: applyFullTime,
            requires_sponsorship: requireSponsorship
        };
        
        try {
            const params = {
                TableName: "auto_apply",
                Key: {
                    client_email: { S: user.email },
                },
                UpdateExpression: "set phone_number = :p, dob = :d, user_location = :l, major = :m, major2 = :m2, minor = :mi, work_experience = :we, project_list = :pl, link_linkedin = :li, link_github = :gh, link_twitter = :tw, link_portfolio = :po, apply_internships = :in, apply_fulltime = :ft, requires_sponsorship = :rs",
                ExpressionAttributeValues: {
                    ":p": { S: autoApplyData.phone_number || "" },
                    ":d": { S: autoApplyData.dob || "" },
                    ":l": { S: autoApplyData.user_location || "" },
                    ":m": { S: autoApplyData.major || "" },
                    ":m2": { S: autoApplyData.major2 || "" },
                    ":mi": { S: autoApplyData.minor || "" },
                    ":we": { L: autoApplyData.work_experience },
                    ":pl": { L: autoApplyData.project_list },
                    ":li": { S: autoApplyData.link_linkedin || "" },
                    ":gh": { S: autoApplyData.link_github || "" },
                    ":tw": { S: autoApplyData.link_twitter || "" },
                    ":po": { S: autoApplyData.link_portfolio || "" },
                    ":in": { BOOL: autoApplyData.apply_internships },
                    ":ft": { BOOL: autoApplyData.apply_fulltime },
                    ":rs": { BOOL: autoApplyData.requires_sponsorship}
                },
                ReturnValues: "UPDATED_NEW",
            };
    
            const command = new UpdateItemCommand(params);
            await dynamoDBClient.send(command);
        
            setUnsavedChanges(false);
            setShowSuccessMessage(true);
            setTimeout(() => setShowSuccessMessage(false), 3000);
        } catch (error) {
            console.error('Error updating auto apply data:', error);
        }
    };
    
    
    

    if (isLoading) {
        return <div className="flex justify-center items-center h-screen bg-black text-white">
            <img src={LogoWhite} alt="Superday Logo"></img>
        </div>;
    }



    return (
        <>
        <div className="flex overflow-x-hidden sm:min-w-[1200px]">
            <div className='max-[600px]:w-full max-[600px]:absolute max-[600px]:top-0'>
                {window.innerWidth > 600 ? <DashboardMenu></DashboardMenu> : <Nav></Nav>}
            </div>
        
            <div className="w-full p-12 h-[100vh] overflow-y-scroll">
                <h1 className='mt-10 sm:-mt-1 text-4xl font-medium self-start'>Account Settings</h1>
                <p className='border-b border-gray-400 py-5 '>Recruit and network as your authentic self. 
                {unsavedChanges && (
                    <p className="text-red-500 mt-2">You have unsaved changes*</p>
                )}
                </p>
                <div className='flex border-b mb-10 py-5 border-gray-400 overflow-x-scroll sm:overflow-x-auto text-center'>
                    <Link to="/settings/basicInfo" className={`max-[600px]:pt-4 px-8 p-2 rounded-md border text-sm ${settingName === 'basicInfo' ? 'bg-black text-white' : 'bg-transparent text-black'} hover:bg-black hover:text-white border-black`}>Profile</Link>
                    <Link to="/settings/selectedIndustries" className={`max-[600px]:pt-4 ml-4 px-6 p-2 rounded-md border text-sm ${settingName === 'selectedIndustries' ? 'bg-black text-white' : 'bg-transparent text-black'} hover:bg-black hover:text-white border-black`}>Industries</Link>
                    <Link to="/settings/coffeeEmail" className={`ml-4 px-6 p-2 rounded-md border text-sm ${settingName === 'coffeeEmail' && userParagraphCoffeeChat ? 'bg-black text-white' : !userParagraphCoffeeChat ? 'bg-red-500 text-white border-red-500' : 'bg-transparent text-black'} hover:bg-black hover:text-white border-black hover:border-black`}>Email Template</Link>
                    <Link to="/settings/authenticate" className={`max-[600px]:pt-4 ml-4 px-6 p-2 rounded-md border text-sm ${settingName === 'authenticate' ? 'bg-black text-white' : 'bg-transparent text-black'} hover:bg-black hover:text-white border-black`}>Authenticate</Link>
                    <Link to="/settings/resume" className={`max-[600px]:pt-4 ml-4 px-6 p-2 rounded-md border text-sm ${settingName === 'resume' && resumeFilename ? 'bg-black text-white' : !resumeFilename ? 'bg-red-500 text-white border-red-500' : 'bg-transparent text-black'} hover:bg-black hover:text-white border-black hover:border-black`}>Resume</Link>
                    { userData.subscription === "2" ? 
                        <Link to="/settings/autoApply" className={`ml-4 px-8 p-2 rounded-md border text-sm ${settingName === 'autoApply' ? 'bg-black text-white' : 'bg-transparent text-black'} hover:bg-black hover:text-white border-black`}>Auto Apply</Link> 
                        : 
                        <div className="relative inline-block">
                            <div className="absolute inset-0 flex items-center justify-center bg-gray-300 bg-opacity-50 ml-5 rounded-md pointer-events-none">
                                <FiLock className="text-2xl text-gray-700" />
                            </div>
                            <button className={`ml-4 px-8 p-2 rounded-md border text-sm autoApply bg-gray-300 text-gray-700`} onClick={handleUpgradeToggle}>
                                Auto Apply
                            </button>
                        </div>
                    }

                </div>

                {
                    showSuccessMessage ? (
                        <OnSuccessPopup showSuccessMessage={showSuccessMessage} successMessage={'Your profile has been updated.'}></OnSuccessPopup>
                    ) : null
                }

                {
                    showUpgradeMessage ? (
                        <OnUpgradePopup showMessage={showUpgradeMessage} message={'You need to be a Professional member to access this setting.'}></OnUpgradePopup>
                    ) : null
                }

                {settingName === 'basicInfo' && (
                    <div>
                        <h1 className='text-2xl font-medium self-start'>Basic Information</h1>
                        <p className='py-5'>This information will be used for basic account info, emails, and applications.</p>

                        <div className="flex flex-wrap w-full sm:w-[70%]">
                            <div className="w-full md:w-1/2 mb-4 md:pr-2"> {/* Add some padding or margin on the right for medium screens and above */}
                                <input
                                    className="w-full border border-gray-300 rounded-md py-2 px-4"
                                    placeholder="First name" // Assuming `first_name` variable holds the placeholder text, or it's an actual value.
                                    value={first_name}
                                    onChange={(e) => {
                                        setFirstName(e.target.value);
                                        setUnsavedChanges(true);
                                    }}
                                />
                            </div>

                            <div className="w-full md:w-1/2 mb-4 md:mb-0 md:pl-2"> {/* Optionally add padding on the left for the last name input */}
                                <input
                                    className="w-full border border-gray-300 rounded-md py-2 px-4"
                                    placeholder="Last name"
                                    value={last_name}
                                    onChange={(e) => {
                                        setLastName(e.target.value);
                                        setUnsavedChanges(true);
                                    }}
                                />
                            </div>

                            <div className="w-full mb-4 relative">
                                <p
                                    className="w-full border border-gray-300 rounded-md py-2 px-4 pr-10"
                                    placeholder="University name"
                                    value={university}
                                >
                                    {userData.client_email}
                                </p>
                                <FaLock
                                    className="absolute right-2 top-1/2 transform -translate-y-1/2"
                                    style={{ color: '#333', fontSize: '16px', marginRight: '8px' }}
                                />
                            </div>

                            <div className="w-full mb-4">
                                <input
                                    className="w-full border border-gray-300 rounded-md py-2 px-4"
                                    placeholder="University name"
                                    value={university}
                                    onChange={(e) => {
                                        setUniversity(e.target.value);
                                        setUnsavedChanges(true);
                                    }}
                                />
                            </div>
                            <div className="w-full mb-4">
                                <select
                                    className="border border-gray-300 rounded-md py-2 px-4 w-full"
                                    aria-label="Year in school"
                                    value={year_in_school}
                                    onChange={(e) => {
                                        setYearInSchool(e.target.value);
                                        setUnsavedChanges(true)
                                    }}
                                >
                                    <option value="" disabled hidden>Year in school</option>
                                    <option value="freshman">Freshman</option>
                                    <option value="sophomore">Sophomore</option>
                                    <option value="junior">Junior</option>
                                    <option value="senior">Senior</option>
                                    <option value="masters">Master's Student</option>
                                    <option value="grad">Graduate</option>
                                </select>
                            </div>
                            <button
                                className="px-8 p-2 bg-black text-white rounded-full border border-transparent hover:border-black hover:bg-[transparent] hover:text-black transition-colors duration-500 ease-in-out"
                                onClick={updateBasicInfo}
                            >
                                Update Profile Info
                            </button>
                        </div>
                    </div>
                )}

                {settingName === 'selectedIndustries' &&
                    <div className="w-full">
                        <h1 className='text-2xl font-medium self-start'>Your Industries</h1>
                        <p className='py-5'>You'll receive emails to reach out to professionals these industries as well as apply to jobs in these industries.</p>

                        <div className="space-y-7">
                            <div className="grid grid-cols-2 gap-4">
                                <div className="max-sm:text-xs text-sm max-sm:py-3 hover:cursor-pointer border border-black rounded-lg py-2 flex justify-center items-center" onClick={() => handleJobTypeSelect('Software Engineering')} style={{ backgroundColor: selectedJobTypes.includes('Software Engineering') ? 'black' : 'transparent' }}>
                                    <span className={` ${selectedJobTypes.includes('Software Engineering') ? 'text-white' : 'text-gray-600'}`}>Software Engineering</span>
                                </div>
                                <div className="max-sm:text-xs text-sm max-sm:py-3 hover:cursor-pointer border border-black rounded-lg py- flex justify-center items-center" onClick={() => handleJobTypeSelect('Product Management')} style={{ backgroundColor: selectedJobTypes.includes('Product Management') ? 'black' : 'transparent' }}>
                                    <span className={` ${selectedJobTypes.includes('Product Management') ? 'text-white' : 'text-gray-600'}`}>Product Management</span>
                                </div>
                                <div className="max-sm:text-xs text-sm max-sm:py-3 hover:cursor-pointer border border-black rounded-lg py-2 flex justify-center items-center" onClick={() => handleJobTypeSelect('Consulting')} style={{ backgroundColor: selectedJobTypes.includes('Consulting') ? 'black' : 'transparent' }}>
                                    <span className={` ${selectedJobTypes.includes('Consulting') ? 'text-white' : 'text-gray-600'}`}>Consulting</span>
                                </div>
                                <div className="max-sm:text-xs text-sm max-sm:py-3 hover:cursor-pointer border border-black rounded-lg py-2 flex justify-center items-center" onClick={() => handleJobTypeSelect('Investment Banking')} style={{ backgroundColor: selectedJobTypes.includes('Investment Banking') ? 'black' : 'transparent' }}>
                                    <span className={` ${selectedJobTypes.includes('Investment Banking') ? 'text-white' : 'text-gray-600'}`}>Investment Banking</span>
                                </div>
                                <div className="max-sm:text-xs text-sm max-sm:py-3 hover:cursor-pointer border border-black rounded-lg py-2 flex justify-center items-center" onClick={() => handleJobTypeSelect('Private Equity')} style={{ backgroundColor: selectedJobTypes.includes('Private Equity') ? 'black' : 'transparent' }}>
                                    <span className={` ${selectedJobTypes.includes('Private Equity') ? 'text-white' : 'text-gray-600'}`}>Private Equity</span>
                                </div>
                                <div className="max-sm:text-xs text-sm max-sm:py-3 hover:cursor-pointer border border-black rounded-lg py-2 flex justify-center items-center" onClick={() => handleJobTypeSelect('Venture Capital')} style={{ backgroundColor: selectedJobTypes.includes('Venture Capital') ? 'black' : 'transparent' }}>
                                    <span className={` ${selectedJobTypes.includes('Venture Capital') ? 'text-white' : 'text-gray-600'}`}>Venture Capital</span>
                                </div>
                            </div>

                            {selectedJobTypes.length > 0 ?
                                <div>
                                    <button className="px-8 p-2 bg-black text-white rounded-full border border-transparent hover:border-black hover:bg-[transparent] hover:text-black transition-colors duration-500 ease-in-out" onClick={submitToDB}> Update Industries</button>
                                </div>
                                : <div></div>}
                        </div>
                    </div>}

                    {settingName === 'authenticate' && (
                    <div>
                        <h1 className='text-2xl font-medium self-start'>Authenticate</h1>
                        <p className='py-5'>Superday uses access and refresh tokens associated with your email account to send emails on your behalf.</p>
                        <p className=''>Authentication is required for sending <i>any</i> emails on Superday, and especially to automate your sending. <br/> We will never send emails without your permission.</p>
                        <p className='py-5'>Note: If you are having trouble sending emails, your tokens may be expired. Use this button to create new tokens.</p>
                        <div className="flex flex-wrap w-[70%]">
                            <GoogleOAuth userEmail={user?.email}></GoogleOAuth>
                            <OutlookOAuth userEmail={user?.email} />
                        </div>
                    </div>
                )}

                {settingName === 'coffeeEmail' &&
                    <form className="section" onSubmit={handleParagraphSubmitCoffeeChat}>
                        <div className='flex items-center'>
                            {userParagraphCoffeeChat === '' && (
                                <div className="text-red-500 flex items-center text-3xl">
                                    <IoAlertCircle className="mr-2" />
                                </div>
                            )}
                            <h1 className='text-2xl font-medium'>Email Template</h1>
                        </div>

                        <p className='py-5'>Click on the variables below to add custom fields to your clipboard. This template will be sent to recipients that you're looking to connect with.</p>
                        <div className="flex flex-wrap gap-2 my-2">
                            {variables.map((variable, index) => (
                                <button
                                    key={index}
                                    className="max-[600px]:w-full flex items-center justify-center bg-gray-200 hover:bg-gray-300 text-black rounded px-4 py-2"
                                    onClick={(e) => copyToClipboard(variable.key, variable.key)}
                                    type="button"
                                >
                                    <FiCopy className="left-0 relative" />
                                    <span className="text-sm ml-2">{copyStatus[variable.key] ? 'Copied!' : variable.description}</span>
                                </button>
                            ))}
                        </div>

                        <textarea
                            value={userSubjectLine}
                            onChange={(e) => {
                                setUserSubjectLine(e.target.value);
                                setUnsavedChanges(true);
                            }}
                            placeholder="Type your subject line here..."
                            className="mt-4 p-2 border rounded w-full"
                            style={{ height: '50px' }}
                        />

                        <textarea
                            value={userParagraphCoffeeChat}
                            onChange={(e) => {
                                setUserParagraphCoffeeChat(e.target.value);
                                setUnsavedChanges(true);
                            }}
                            placeholder="Type your email template here..."
                            className="mt-4 p-2 border rounded w-full"
                            style={{ height: '200px' }}
                        />

                        <button type="submit" className="mt-3 px-8 p-2 bg-black text-white rounded-full border border-transparent hover:border-black hover:bg-[transparent] hover:text-black transition-colors duration-500 ease-in-out">
                            Update Email Template
                        </button>
                    </form>
                }

                {settingName === 'recruitingEmail' &&
                    <form className="section" onSubmit={handleParagraphSubmitNetwork}>
                        <div className='flex'>
                            {userParagraphNetwork === '' && (
                                <div className="text-red-500 flex items-center text-3xl">
                                    <IoAlertCircle className="mr-2" />
                                </div>
                            )}
                            <h1 className='text-2xl font-medium self-start'>Recruiting Email</h1>
                        </div>

                        <p className=' border-gray-400 py-5'>Click on the variables below to add custom fields to your clipboard. This template will be sent to recipients that you're looking to recruit for.</p>

                        <div className="flex flex-wrap gap-2 my-2">
                            {variables.map((variable, index) => (
                                <button
                                    key={index}
                                    className="flex items-center bg-gray-200 hover:bg-gray-300 text-black rounded px-4 py-2"
                                    onClick={(e) => copyToClipboard(variable.key, variable.key)}
                                    type="button"
                                >
                                    <FiCopy className="ml-2" />
                                    <span className="ml-2 text-sm">{copyStatus[variable.key] ? 'Copied!' : variable.description}</span>
                                </button>
                            ))}
                        </div>

                        <textarea
                            value={userParagraphNetwork}
                            onChange={(e) => {
                                setUserParagraphNetwork(e.target.value);
                                setUnsavedChanges(true);
                            }}
                            placeholder="Type your email here..."
                            className="mt-4 p-2 border rounded w-full"
                            style={{ height: '200px' }}
                        />

                        <button type="submit" className="mt-3 px-8 p-2 bg-black text-white rounded-full border border-transparent hover:border-black hover:bg-[transparent] hover:text-black">
                            Update
                        </button>
                    </form>
                }

                {settingName === 'resume' &&
                    <form className="section" onSubmit={handleResumeSubmit} encType="multipart/form-data">
                        <div className='flex'>
                            {resumeFilename === '' && (
                                <div className="text-red-500 flex items-center text-3xl">
                                    <IoAlertCircle className="mr-2" />
                                </div>
                            )}
                            <h1 className='text-2xl font-medium self-start'>Resume Upload</h1>
                        </div>

                        <p className='border-gray-400 py-5'>This resume will be attached to emails or applications that you send out.</p>

                        <div className="mb-3">
                            <label htmlFor="resumeUpload" className="block text-sm font-medium text-gray-700">
                                Upload your resume (PDF only):
                            </label>
                            <input
                                type="file"
                                id="resumeUpload"
                                name="resume"
                                onChange={handleFileChange}
                                accept="application/pdf"
                                className="mt-1 block w-full"
                            />
                        </div>
                        <div className='flex items-center mt-6'>
                            <button type="submit" className="px-8 p-2 bg-black text-white  border-black border rounded-full hover:border-black hover:bg-[transparent] hover:text-black hover:border transition-colors duration-500 ease-in-out">
                                Update Resume
                            </button>
                            <div onClick={viewResume}>
                                {resumeFilename ? (
                                    <div className='bg-transparent py-2 px-10 ml-4 border border-black rounded-lg'>
                                        <button>View Current Resume</button>
                                    </div>
                                ) : (
                                    <p className='ml-4'>No resume currently uploaded.</p>
                                )}
                            </div>

                        </div>
                    </form>
                }

                {settingName === 'autoApply' && (
                    <div>
                        <h1 className='text-2xl font-medium self-start'>Auto Apply</h1>
                        <p className='mt-5'>This information will be used in combination with your other information for job applications.</p>
                        <p className='mb-4'>Please fill in <b>all fields</b> in order to get the best results.</p>

                        <div className="flex flex-wrap w-full sm:w-[70%]">
                            {/* Phone */}
                            <div className="w-full md:w-1/2 mb-4 md:pr-2">
                                <label className="block mb-1">Phone</label>
                                <input type="text" className="w-full border px-3 py-2" value={phone} onChange={(e) => { setPhone(e.target.value); setUnsavedChanges(true); }} />
                            </div>

                            {/* Date of Birth */}
                            <div className="w-full md:w-1/2 mb-4 md:pl-2">
                                <label className="block mb-1">Date of Birth</label>
                                <input type="date" className="w-full border px-3 py-2" value={dob} onChange={(e) => { setDob(e.target.value); setUnsavedChanges(true); }} />
                            </div>

                            {/* Location */}
                            <div className="w-full mb-4">
                                <label className="block mb-1">Location</label>
                                <input type="text" className="w-full border px-3 py-2" value={userLocation} onChange={(e) => { setUserLocation(e.target.value); setUnsavedChanges(true); }} />
                            </div>

                            {/* Major 1 */}
                            <div className="w-full md:w-1/2 mb-4 md:pr-2">
                                <label className="block mb-1">Major 1</label>
                                <input type="text" className="w-full border px-3 py-2" value={major1} onChange={(e) => { setMajor1(e.target.value); setUnsavedChanges(true); }} />
                            </div>

                            {/* Major 2 */}
                            <div className="w-full md:w-1/2 mb-4 md:pl-2">
                                <label className="block mb-1">Major 2 (optional)</label>
                                <input type="text" className="w-full border px-3 py-2" value={major2} onChange={(e) => { setMajor2(e.target.value); setUnsavedChanges(true); }} />
                            </div>

                            {/* Minor */}
                            <div className="w-full mb-4">
                                <label className="block mb-1">Minor (optional)</label>
                                <input type="text" className="w-full border px-3 py-2" value={minor} onChange={(e) => { setMinor(e.target.value); setUnsavedChanges(true); }} />
                            </div>

                            {/* Work Experience */}
                            <div className="w-full mb-4 mt-4">
                                <h2 className="text-xl font-medium mb-4">Work Experience</h2>
                                {workExperiences.map((work, index) => (
                                    <div key={index} className="relative mb-4 p-4 border rounded-md">
                                        <button onClick={() => { removeWorkExperience(index); setUnsavedChanges(true); }} className="absolute top-0 right-0 mt-2 mr-2">
                                            <FiX />
                                        </button>
                                        <label className="block mb-1">Job Title</label>
                                        <input type="text" className="w-full border px-3 py-2 mb-2" value={work.jobTitle} onChange={(e) => { handleWorkExperienceChange(index, 'jobTitle', e.target.value); setUnsavedChanges(true); }} />
                                        <label className="block mb-1">Employer</label>
                                        <input type="text" className="w-full border px-3 py-2 mb-2" value={work.employer} onChange={(e) => { handleWorkExperienceChange(index, 'employer', e.target.value); setUnsavedChanges(true); }} />
                                        <label className="block mb-1">Employer Location</label>
                                        <input type="text" className="w-full border px-3 py-2 mb-2" value={work.location} onChange={(e) => { handleWorkExperienceChange(index, 'location', e.target.value); setUnsavedChanges(true); }} />
                                        <label className="block mb-1">Dates Employed</label>
                                        <input type="text" className="w-full border px-3 py-2 mb-2" value={work.dates} onChange={(e) => { handleWorkExperienceChange(index, 'dates', e.target.value); setUnsavedChanges(true); }} />
                                        <label className="block mb-1">Achievements & Responsibilities</label>
                                        <textarea className="w-full border px-3 py-2 mb-2" value={work.responsibilities} onChange={(e) => { handleWorkExperienceChange(index, 'responsibilities', e.target.value); setUnsavedChanges(true); }}></textarea>
                                    </div>
                                ))}
                                <button onClick={() => { addWorkExperience(); setUnsavedChanges(true); }} className="px-4 py-2 mb-5 bg-black text-white rounded-md flex items-center space-x-2">
                                    <span>Add Work Experience</span> <FiPlus />
                                </button>
                            </div>

                            {/* Projects and Outside Experience */}
                            <div className="w-full mb-4">
                                <h2 className="text-xl font-medium mb-4">Projects and Outside Experience</h2>
                                {projects.map((project, index) => (
                                    <div key={index} className="relative mb-4 p-4 border rounded-md">
                                        <button onClick={() => { removeProject(index); setUnsavedChanges(true); }} className="absolute top-0 right-0 mt-2 mr-2">
                                            <FiX />
                                        </button>
                                        <label className="block mb-1">Project Name</label>
                                        <input type="text" className="w-full border px-3 py-2 mb-2" value={project.name} onChange={(e) => { handleProjectChange(index, 'name', e.target.value); setUnsavedChanges(true); }} />
                                        <label className="block mb-1">Project Title</label>
                                        <input type="text" className="w-full border px-3 py-2 mb-2" value={project.title} onChange={(e) => { handleProjectChange(index, 'title', e.target.value); setUnsavedChanges(true); }} />
                                        <label className="block mb-1">Location</label>
                                        <input type="text" className="w-full border px-3 py-2 mb-2" value={project.location} onChange={(e) => { handleProjectChange(index, 'location', e.target.value); setUnsavedChanges(true); }} />
                                        <label className="block mb-1">To and From Dates</label>
                                        <input type="text" className="w-full border px-3 py-2 mb-2" value={project.dates} onChange={(e) => { handleProjectChange(index, 'dates', e.target.value); setUnsavedChanges(true); }} />
                                        <label className="block mb-1">Description</label>
                                        <textarea className="w-full border px-3 py-2 mb-2" value={project.description} onChange={(e) => { handleProjectChange(index, 'description', e.target.value); setUnsavedChanges(true); }}></textarea>
                                        <label className="block mb-1">Link</label>
                                        <input type="text" className="w-full border px-3 py-2 mb-2" value={project.link} onChange={(e) => { handleProjectChange(index, 'link', e.target.value); setUnsavedChanges(true); }} />
                                    </div>
                                ))}
                                <button onClick={() => { addProject(); setUnsavedChanges(true); }} className="px-4 py-2 mb-5 bg-black text-white rounded-md flex items-center space-x-2">
                                    <span>Add Project</span> <FiPlus />
                                </button>
                            </div>

                            {/* Links */}
                            <div className="w-full mb-4">
                                <h2 className="text-xl font-medium mb-4">Links</h2>
                                <label className="block mb-1">LinkedIn</label>
                                <input type="text" className="w-full border px-3 py-2 mb-2" value={linkedin} onChange={(e) => { setLinkedin(e.target.value); setUnsavedChanges(true); }} />
                                <label className="block mb-1">GitHub</label>
                                <input type="text" className="w-full border px-3 py-2 mb-2" value={github} onChange={(e) => { setGithub(e.target.value); setUnsavedChanges(true); }} />
                                <label className="block mb-1">Twitter</label>
                                <input type="text" className="w-full border px-3 py-2 mb-2" value={twitter} onChange={(e) => { setTwitter(e.target.value); setUnsavedChanges(true); }} />
                                <label className="block mb-1">Portfolio</label>
                                <input type="text" className="w-full border px-3 py-2 mb-2" value={portfolio} onChange={(e) => { setPortfolio(e.target.value); setUnsavedChanges(true); }} />
                            </div>

                            {/* Job Types */}
                            <div className="w-full mb-4">
                                <div className="flex items-center mb-4">
                                    <h2 className="text-xl font-medium mr-36">Job Types</h2>
                                    <InfoTooltip text={"Note: Selecting to apply to both Internships and Full Time positions \nwill not alter the total number of applications you are given."} />
                                </div>
                                <div className="flex items-center space-x-4">
                                    <label className="flex items-center space-x-2">
                                        <input 
                                            type="checkbox" 
                                            className="form-checkbox h-5 w-5 text-blue-600" 
                                            checked={applyInternships} 
                                            onChange={(e) => { 
                                                setApplyInternships(e.target.checked); 
                                                setUnsavedChanges(true); 
                                            }} 
                                        />
                                        <span className="text-gray-700">Internships</span>
                                    </label>
                                    
                                    <label className="flex items-center space-x-2">
                                        <input 
                                            type="checkbox" 
                                            className="form-checkbox h-5 w-5 text-blue-600" 
                                            checked={applyFullTime} 
                                            onChange={(e) => { 
                                                setApplyFullTime(e.target.checked); 
                                                setUnsavedChanges(true); 
                                            }} 
                                        />
                                        <span className="text-gray-700">Full Time Roles</span>
                                    </label>
                                </div>
                            </div>

                            {/* Sponsorship */}
                            <div className="w-full mb-4 mt-4">
                                <div className="flex items-center mb-4">
                                    <h2 className="text-xl font-medium mr-10">Do you require sponsorship for employment visa status (e.g H1B visa status)?</h2>
                                    <InfoTooltip text={"Note: This field will only be used when the application prompts for sponsorship requirements."} />
                                </div>
                                <div className="flex items-center space-x-4">
                                    <label className="flex items-center space-x-2">
                                        <input 
                                            type="checkbox" 
                                            className="form-checkbox h-5 w-5 text-blue-600" 
                                            checked={requireSponsorship} 
                                            onChange={(e) => { 
                                                setRequiresSponsorship(e.target.checked); 
                                                setUnsavedChanges(true); 
                                            }} 
                                        />
                                        <span className="text-gray-700">Yes</span>
                                    </label>
                                </div>
                            </div>

                        </div>

                        <button
                            className="mt-4 px-8 p-2 bg-black text-white rounded-full border border-transparent hover:border-black hover:bg-[transparent] hover:text-black transition-colors duration-500 ease-in-out"
                            onClick={handleAutoApplySubmit}
                        >
                            Save
                        </button>
                    </div>
                )}


            </div >
        </div >
        
        </>
        
    );
}