import React, { useState, useEffect, useCallback } from "react";
import { AiFillDelete } from "react-icons/ai";
import {
  getUserData,
  removeItem,
  addToUserAttribute,
  addInitialEmails,
} from "../../components/awsAuth";
import { useAuth0 } from "@auth0/auth0-react";
import GoogleOAuthSendEmails from "../approve/GoogleOAuthSendEmails";
import RefreshSendEmails from "../approve/RefreshSendEmails";
import { Link } from "react-router-dom";
import OnSuccessPopup from "../../components/OnSuccessPopup";
import { FiCopy } from "react-icons/fi";
import SendSettingsMobile from "../approvemobile/sendSettingsMobile";
import { useTour } from "@reactour/tour";
import cookie from "cookiejs";
import InfoTooltip from "../../components/InfoTooltip";
import OutlookOAuthSendEmails from '../approve/OutlookOAuthSendEmails';



const {
  DynamoDBClient,
  UpdateItemCommand,
} = require("@aws-sdk/client-dynamodb");

export default function ApproveMobile(props) {
  const { user } = useAuth0();
  const [emailInfo, setEmailInfo] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedEmailType, setSelectedEmailType] = useState("coffee");
  const [selectAll, setSelectAll] = useState(true);
  const [userParagraphCoffeeChat, setUserParagraphCoffeeChat] = useState("");
  const [userSubjectLine, setUserSubjectLine] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [copyStatus, setCopyStatus] = useState({});
  const [sentOrToSend, setSentOrToSend] = useState("toSend");
  const [autoSend, setAutoSend] = useState();
  const [pageValue, setPageValue] = useState(0);
  const [sentPageValue, setSentPageValue] = useState(0);
  const [numEmails, setNumEmails] = useState(0);

  const emailsPerPage = emailInfo.filter(
    (email) =>
      email.email_status === "Unconfirmed" || email.email_status === "Confirmed"
  ).length;
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [refreshToken, setRefreshToken] = useState(null);
  const [tourMode, setTourMode] = useState(false);
  const { setIsOpen, currentStep, setCurrentStep } = useTour();
  const [unsavedChangesNotif, setUnsavedChangesNotif] = useState(false);
  const [showEmailConfirmationPopup, setEmailConfirmationPopup] = useState(false);
  const [userSubscription, setUserSubscription] = useState("0");

  useEffect(() => {
    if (showEmailConfirmationPopup) {
      document.body.style.overflow = 'hidden'; // Disable scrolling when tour is active
      document.documentElement.style.overflow = 'hidden'; // Disable scrolling for the root element
      console.log('Scrolling disabled');
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling when tour is inactive
      document.documentElement.style.overflow = 'auto'; // Enable scrolling for the root element
      console.log('Scrolling enabled');
    }

    return () => {
      document.body.style.overflow = 'auto'; // Cleanup: enable scrolling when component unmounts
      document.documentElement.style.overflow = 'auto'; // Cleanup: enable scrolling for the root element
    };
  }, [showEmailConfirmationPopup]);




  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const awsCredentials = {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  };

  const dynamoDBClient = new DynamoDBClient({
    credentials: awsCredentials,
    region: awsCredentials.region,
  });

  const variables = [
    { key: "[their_first_name]", description: "Their first name" },
    { key: "[relevant_industry]", description: "Relevant industry" },
    { key: "[their_company]", description: "Their company" },
  ];

  const copyToClipboard = async (text, variableKey) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopyStatus((prevStatus) => ({ ...prevStatus, [variableKey]: true }));

      setTimeout(() => {
        setCopyStatus((prevStatus) => ({
          ...prevStatus,
          [variableKey]: false,
        }));
      }, 1000);
    } catch (err) {
      console.error("Failed to copy!", err);
    }
  };

  const onEmailsSent = async () => {
    if (user?.email) {
      try {
        if (numEmails == 1) {
          await addInitialEmails(user?.email);
        }


        // Fetch the updated email data
        let updatedEmails = await getUserData(user?.email);
        updatedEmails = updatedEmails.emails_to_send;
        setNumEmails(updatedEmails.length);

        // Update the state with the fetched email data
        setEmailInfo(updatedEmails);

        // Update the selected emails based on the new email data
        setSelectedEmails(
          updatedEmails.filter(
            (email) =>
              email.email_status === "Confirmed" ||
              email.email_status === "Unconfirmed"
          )
        );

        // Close the email confirmation popup
        setEmailConfirmationPopup(false);

        // Optionally show a success message
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000);
      } catch (error) {
        console.error("Failed to fetch updated emails:", error);
      }
    }
  };

  useEffect(() => {
    setTourMode(!cookie("completedTutorial"));
    setIsOpen(!cookie("completedTutorial"));
    const fetchEmails = async () => {
      if (user?.email) {
        try {
          let initialEmails = await getUserData(user?.email);
          setAutoSend(initialEmails.auto_send ?? false);
          setNumEmails(initialEmails.emails_to_send.length);
          setUserParagraphCoffeeChat(initialEmails.paragraph_coffee_chat ?? '');
          setUserSubjectLine(initialEmails.paragraph_subject_line ?? '');
          setRefreshToken(initialEmails.refresh_token ?? null);
          setUserSubscription(initialEmails.subscription);
          initialEmails = initialEmails.emails_to_send ?? [];
          setEmailInfo(initialEmails);
          setSelectedEmails(initialEmails.filter(email => email.email_status === 'Confirmed' || email.email_status === 'Unconfirmed'));
        } catch (error) {
          console.log("Failed to fetch emails:", error);
        }
      }
    };
    fetchEmails();
  }, [user?.email]);

  const toggleAllEmails = useCallback(() => {
    if (!selectAll) {
      setSelectedEmails(
        emailInfo.filter((email) => email.email_status === "Unconfirmed")
      );
    } else {
      setSelectedEmails([]);
    }
    setSelectAll(!selectAll);
  }, [emailInfo, selectAll]);

  const toggleEmailSelection = (email, isChecked) => {
    if (isChecked) {
      setSelectedEmails((prev) => [...prev, email]);
    } else {
      setSelectedEmails((prev) =>
        prev.filter(
          (selectedEmail) => selectedEmail.company_email !== email.company_email
        )
      );
    }
  };


  const removeEmail = async (emailToRemove) => {
    setEmailInfo((prev) =>
      prev.filter(
        (email) => email.company_email !== emailToRemove.company_email
      )
    );
    setSelectedEmails((prev) =>
      prev.filter(
        (selectedEmail) =>
          selectedEmail.company_email !== emailToRemove.company_email
      )
    );
    const clientEmail = user?.email;
    removeItem(clientEmail, emailToRemove.company_email);
    await addToUserAttribute(clientEmail, "num_unsent_emails", -1);
  };

  const handleParagraphSubmitCoffeeChat = async (event) => {
    event.preventDefault();

    const params = {
        TableName: "internify",
        Key: {
            client_email: { S: user.email }
        },
        UpdateExpression: "set paragraph_coffee_chat = :p, paragraph_subject_line = :s",
        ExpressionAttributeValues: {
            ":p": { S: userParagraphCoffeeChat },
            ":s": { S: userSubjectLine }
        },
        ReturnValues: "UPDATED_NEW"
    };

    try {
        const command = new UpdateItemCommand(params);
        const response = await dynamoDBClient.send(command);
        console.log("Success", response);
        setShowSuccessMessage(true);
        setUnsavedChangesNotif(false);
        setTimeout(() => setShowSuccessMessage(false), 3000);
    } catch (error) {
        console.error(error);
        console.log("Error");
    }
};



  const SentEmails = ({ emails, sentPageValue, emailsPerPage }) => {
    const paginatedSentEmails = emails
      .filter((email) => email.email_status === "Sent")
      .sort((a, b) => new Date(b.day_sent) - new Date(a.day_sent))
      .slice(
        sentPageValue * emailsPerPage,
        (sentPageValue + 1) * emailsPerPage
      );

    return (
      <div className="overflow-x-hidden max-w-min">
        <div className="overflow-y-scroll h-[271px]">
          <table className="bg-white table-auto">
            <thead>
              <tr>
                <th className="z-10 sticky top-0 px-5 py-3 border-b-2 bg-gray-100 text-gray-500 border-gray-100 text-left text-sm font-semibold uppercase tracking-wider shadow-lg">
                  Info
                </th>
                <th className="z-10 sticky top-0 px-5 py-3 border-b-2 bg-gray-100 text-gray-500 border-gray-100 text-left text-sm font-semibold uppercase tracking-wider shadow-lg">
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedSentEmails.map((email, index) => (
                
                <tr key={index}>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-xs text-left">
                    {email.recipient_name}
                    <br></br>
                    {email.company_name}
                    <br></br>
                    {email.company_email}
                  </td>

                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-xs text-left">
                    {email.day_sent ? (
                      <>
                        <div>
                          {new Date(email.day_sent).toLocaleDateString(
                            "en-US",
                            {
                              year: "2-digit",
                              month: "numeric",
                              day: "numeric",
                            }
                          )}
                        </div>
                        <div>
                          {new Date(email.day_sent).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "numeric",
                              minute: "2-digit",
                              hour12: true,
                            }
                          )}
                        </div>
                      </>
                    ) : (
                      "Not Sent"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const DualEmailTable = React.memo(
    ({ emails, selectedEmails, toggleEmailSelection, removeEmail }) => {
      const memoizedToggleEmailSelection = useCallback(
        (email, isSelected) => {
          toggleEmailSelection(email, isSelected);
        },
        [toggleEmailSelection]
      );

      const memoizedRemoveEmail = useCallback(
        (email) => {
          removeEmail(email);
        },
        [removeEmail]
      );

      return (
        <div
          className="overflow-x-hidden overflow-y-scroll h-[271px]"
          style={{ scrollbarWidth: "thin" }}
          data-tour="onboard-sendemail-1"
        >
          <table className="bg-white">
            <thead>
              <tr>
                <th className="z-10 sticky top-0 px-5 py-3 border-b-2 bg-gray-100 text-gray-500 border-gray-100 text-left text-sm font-semibold uppercase tracking-wider shadow-lg">
                  Info
                </th>
                <th className="z-10 sticky top-0 px-5 py-3 border-b-2 bg-gray-100 text-gray-500 border-gray-100 text-left text-sm font-semibold uppercase tracking-wider shadow-lg">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="accent-black scale-125 mr-3"
                      checked={selectAll}
                      onChange={toggleAllEmails}
                    />
                    Approve
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {emails
                .filter(
                  (email) =>
                    email.email_status === "Unconfirmed" ||
                    email.email_status === "Confirmed"
                )
                .slice(
                  pageValue * emailsPerPage,
                  (pageValue + 1) * emailsPerPage
                )
                .map((email, index) => (
                  <tr key={index} {...(index === 0 ? {} : {})}>
                    <td className="px-5 py-3 border-b border-gray-100 bg-white text-xs text-left">
                      {email.recipient_name}
                      <br></br>
                      {email.role}
                      <br></br>
                      {email.company_name}
                    </td>

                    <td className="px-5 py-3 border-b border-gray-100 bg-white text-xs text-left">
                      <div className="flex">
                        <input
                          type="checkbox"
                          className="accent-black scale-100 mr-3 leading-tight checkbox-black-bg"
                          checked={selectedEmails.some(
                            (selectedEmail) =>
                              selectedEmail.company_email ===
                              email.company_email
                          )}
                          onChange={() =>
                            memoizedToggleEmailSelection(
                              email,
                              !selectedEmails.some(
                                (selectedEmail) =>
                                  selectedEmail.company_email ===
                                  email.company_email
                              )
                            )
                          }
                        />
                        <button
                          onClick={() => memoizedRemoveEmail(email)}
                          className="text-red-500 hover:text-red-700 text-xl"
                        >
                          <AiFillDelete />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      );
    }
  );

  return (
    <>
      <div className="flex p-8 overflow-x-hidden flex-row">
        {showEmailConfirmationPopup && (
          <div className="top-0 absolute inset-0 bg-black bg-opacity-75 z-[269] flex items-center justify-center overflow-y-hidden">
            <div className="w-11/12 max-w-sm relative px-12 py-10 bg-white rounded-xl">
              <button
                className="absolute top-4 right-4 text-black text-3xl"
                onClick={() => setEmailConfirmationPopup(false)}
              >
                &times;
              </button>
              <h1 className="font-medium text-2xl text-center">
                Confirm Sending Emails
              </h1>
              <h1 className="text-md text-center mt-4 text-gray-500">
                You are about to send {selectedEmails.length} emails. Are you
                sure you want to proceed?
              </h1>
              <h1 className="text-md text-center mt-2 text-gray-500">
                Click a button below to continue.
              </h1>
              <div className="flex-row mt-8 w-full align-center justify-center">
                <button
                  className="mb-5 border-black border bg-black px-8 py-2 mr-10 rounded-3xl text-white hover:bg-transparent hover:text-black transition-colors duration-500 ease-in-out w-full"
                  onClick={() => setEmailConfirmationPopup(false)}
                >
                  Not yet
                </button>
                {
                                refreshToken ? (
                                    <RefreshSendEmails
                                    userEmail={user?.email}
                                    fullName={user?.fullName}
                                    allEmails={emailInfo}
                                    emailsToUpdate={selectedEmails}
                                    numToSend={selectedEmails.length}
                                    selectedEmailType={selectedEmailType}
                                    onEmailsSent={onEmailsSent}
                                    />
                                ) : (
                                    <>
                                    <GoogleOAuthSendEmails
                                        userEmail={user?.email}
                                        fullName={user?.fullName}
                                        allEmails={emailInfo}
                                        emailsToUpdate={selectedEmails}
                                        numToSend={selectedEmails.length}
                                        selectedEmailType={selectedEmailType}
                                    />
                                    <OutlookOAuthSendEmails
                                        userEmail={user?.email}
                                        fullName={user?.fullName}
                                        allEmails={emailInfo}
                                        emailsToUpdate={selectedEmails}
                                        numToSend={selectedEmails.length}
                                        selectedEmailType={selectedEmailType}
                                    />
                                    </>
                                )
                }
              </div>
            </div>
          </div>
        )}

        <div className="left-0 flex flex-col absolute mt-9 w-full">

          <div className="FIRST w-full">

            <div className=" bg-white p-8 border border-gray-300 rounded-2xl w-full">
              <div className="mb-8">
                <div className="items-center">
                  <div>
                    <h1 className="font-medium text-2xl">
                      Emails to Send (
                      {
                        emailInfo.filter(
                          (email) =>
                            email.email_status === "Unconfirmed" ||
                            email.email_status === "Confirmed"
                        ).length
                      }
                      ){" "}
                    </h1>
                    <div className="flex items-center mt-5">
                      <button
                        onClick={() => setSentOrToSend("toSend")}
                        type="submit"
                        className={`text-xs px-4 py-2 border rounded-md ${
                          sentOrToSend === "toSend"
                            ? "opacity-100 bg-black text-black bg-transparent border-black border"
                            : "text-gray-400 border-gray-300"
                        } mr-4`}
                      >
                        Emails to send
                      </button>
                      <button
                        onClick={() => setSentOrToSend("Sent")}
                        type="submit"
                        className={`text-xs px-4 py-2 border rounded-md ${
                          sentOrToSend === "Sent"
                            ? "opacity-100 bg-black text-black bg-transparent border-black border"
                            : "text-gray-400 border-gray-300"
                        }`}
                      >
                        Sent email history
                      </button>
                    </div>
                    <div className="mt-5">
                      <button
                        className="border-black border bg-black px-8 py-2 rounded-3xl text-white hover:bg-transparent hover:text-black transition-colors duration-500 ease-in-out"
                        onClick={(e) => {
                          setEmailConfirmationPopup(true);
                        }}
                      >
                        Send Selected ({selectedEmails.length})
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {sentOrToSend === "toSend" ? (
                <div className="">
                  <DualEmailTable
                    emails={emailInfo}
                    selectedEmails={selectedEmails}
                    toggleEmailSelection={toggleEmailSelection}
                    removeEmail={removeEmail}
                  />
                </div>
              ) : (
                <div className="">
                  <SentEmails
                    emails={emailInfo}
                    sentPageValue={sentPageValue}
                    emailsPerPage={emailsPerPage}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="mt-4 mb-4 SECOND w-full">
            <form
              className="section bg-white border border-gray-300 p-8 rounded-2xl"
              onSubmit={handleParagraphSubmitCoffeeChat}
              data-tour="onboard-setemail-1"
            >
              <div className="flex justify-between items-center w-full mb-4">
                <h1 className="mr-2 font-medium text-3xl">
                  Customize Template
                </h1>
                <Link
                  to="/settings/resume"
                  className="text-md hover:opacity-50 text-gray-400"
                >
                  Add or edit resume
                </Link>
              </div>

              {unsavedChangesNotif && (
                <p className="text-red-500 mt-2">You have unsaved changes*</p>
              )}

              <div data-tour="onboard-setemail-3">
                <textarea
                  value={userSubjectLine}
                  onChange={(e) => {
                    setUserSubjectLine(e.target.value);
                    setUnsavedChangesNotif(true);
                  }}
                  placeholder="Type your subject line here..."
                  className="mt-2 p-1 border rounded w-full text-gray-500 text-sm"
                  style={{ height: "50px" }}
                />

                <textarea
                  value={userParagraphCoffeeChat}
                  onChange={(e) => {
                    setUserParagraphCoffeeChat(e.target.value);
                    setUnsavedChangesNotif(true);
                  }}
                  placeholder="Type your email template here..."
                  className="mt-2 p-1 border rounded w-full text-gray-500 text-sm"
                  style={{ height: "180px" }}
                />

                <div className="flex items-center w-full justify-end mt-3">
                  <button
                    type="submit"
                    className="px-8 p-2 hover:bg-transparent border text-white border-black rounded-full bg-black hover:text-black transition-colors duration-500 ease-in-out"
                  >
                    Save
                  </button>
                </div>
              </div>

              <div className="">
                <h1 className="font-medium text-lg">How it works</h1>
                <p className="mt-2 text-sm text-gray-500">
                  Use variables to customize your email for the person receiving
                  it. Click on a variable to copy it and then paste it into your
                  email.
                </p>
                <div
                  className="flex flex-wrap gap-2 mt-6"
                  data-tour="onboard-setemail-2"
                >
                  {variables.map((variable, index) => (
                    <button
                      key={index}
                      className="flex items-center bg-gray-200 hover:bg-gray-300 text-black rounded px-4 py-2"
                      onClick={() => copyToClipboard(variable.key)}
                      type="button"
                    >
                      <FiCopy />
                      <span className="ml-2 text-[12px]">
                        {copyStatus[variable.key]
                          ? "Copied!"
                          : variable.description}
                      </span>
                    </button>
                  ))}
                </div>

                <div class="mt-6 flex-col">
                  <div className="flex-col grid grid-cols-2 gap-4 text-sm text-gray-500">
                    <div class="col-span-2">
                      <p class="font-semibold mb-1">
                        Example: John Doe works in investment banking at Goldman
                        Sachs
                      </p>
                    </div>
                    <div class="-mb-2">
                      <p class="mb-0">[their_first_name] = John</p>
                    </div>
                    <div class="mb-0">
                      <p class="mb-0">
                        [relevant_industry] = Investment Banking
                      </p>
                    </div>
                    <div class="mb-0">
                      <p class="mb-0">[their_company] = Goldman Sachs</p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="w-full">
          <SendSettingsMobile userSubscription={userSubscription} />
          </div>
        </div>
      </div>
    </>
  );
}
