import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getUserData } from "../../components/awsAuth";
import DashboardMenu from "../../components/dashboard/dashboardMenu";
import DashboardProfile from "../../components/dashboard/dashboardProfile";
import { useAuth0 } from "@auth0/auth0-react";
import SignInSignOutButton from "../../components/signInSignOut/signInSignOutButton";
import EditInfo from "../../components/dashboard/editInfo";
import { useNavigate } from "react-router-dom";
import LogoWhite from "../../images/logoWhite.svg";
import Logo from "../../images/logo.svg";
import Nav from "../../pages/nav/nav";
import Approve from "../../pages/approve/approve";
import ApproveMobile from "../../pages/approvemobile/approvemobile";
import { FaRegCircleXmark } from "react-icons/fa6";
import { MdStars } from "react-icons/md";
import StripeUpgrade from "../../components/StripeUpgrade";
import cookie from "cookiejs";
import GoogleOAuth from "../approve/GoogleOAuth";
import OutlookOAuth from "../approve/OutlookOAuth";

export default function Dashboard(props) {
  const { user, isAuthenticated, isLoading: authLoading } = useAuth0();
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const editProfilePage = location.pathname === "/dashboard/profile";
  const [isMobile, setIsMobile] = useState(false);
  const [needsUploadCoffeeChat, setNeedsUploadCoffeeChat] = useState(false);
  const [userSubscription, setUserSubscription] = useState(0);
  const [googleAuth, setGoogleAuth] = useState();
  const navigate = useNavigate();


  useEffect(() => {
    if (isAuthenticated) {
      getUserData(user?.email)
        .then((data) => {
          setUserData(data); // Assuming data contains the user data you want to use
          setIsLoading(false); // Update loading state
          if (!data?.industry) {
            navigate("/onboard"); // Navigate to the onboard route
            window.location.reload(); // Refresh the page
          }

          // Check if a premium user does not have data uploaded
          if (data.subscription == 1 || data.subscription == 2) {
            if (data.paragraph_coffee_chat === "") {
              setNeedsUploadCoffeeChat(true);
            } else {
              setNeedsUploadCoffeeChat(false);
            }
          }
          setUserSubscription(data.subscription);
          setGoogleAuth(data.access_token); // Set the state with the token or null
        })
        .catch((err) => {
          return (
            <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
              <div
                className="bg-[#f8fbff] rounded-xl shadow-lg text-gray-800 p-12 relative flex justify-center flex-col items-center"
                style={{ maxWidth: "500px" }}
              >
                Log in to view dashboard
                <br></br>
                <br></br>
                <SignInSignOutButton title="Sign in or create account"></SignInSignOutButton>
              </div>
            </div>
          );
        });
    } else {
      setIsLoading(authLoading); // Reflect auth loading state
    }
  }, [user?.email, isAuthenticated, authLoading, navigate]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-black text-white">
        <img src={LogoWhite}></img>
      </div>
    );
  }

  if (!userData) {
    navigate("/");
    return null; // Render nothing while redirecting
  }

  if (window.innerWidth < 600) {
    if (editProfilePage) return <EditInfo></EditInfo>;

    return (
      <>
        <Nav></Nav>
        <div className="flex min-w-[1200px]">
          {!googleAuth && (
            <div className="fixed inset-0 bg-black bg-opacity-75 z-[269] flex justify-center items-center">
              <div className="px-12 py-10 bg-white rounded-xl">
                <h1 className="font-medium text-4xl text-center">
                  Connect your email address
                </h1>
                <h1 className="text-lg text-center mt-4 text-gray-500">
                  Superday requires permission to send emails on your behalf
                </h1>
                <h4 className="text-md text-center mt-4 text-gray-500">
                  (Don't worry, we'll never send emails without your permission)
                </h4>
                <div className="flex mt-8 w-full align-center justify-center">
                  <GoogleOAuth userEmail={user?.email}></GoogleOAuth>
                  <OutlookOAuth userEmail={user?.email} />
                </div>
              </div>
            </div>
          )}

          {isLoading ? (
            <div>Loading user data...</div>
          ) : (
            <div className="w-full max-h-[100vh] overflow-y-scroll">
              <ApproveMobile></ApproveMobile>
            </div>
          )}
        </div>
      </>
    );
  }

  if (editProfilePage) return <EditInfo></EditInfo>;

  if (!editProfilePage)
    return (
      <div className="flex min-w-[1200px]">
        {!googleAuth && (
          <div className="fixed inset-0 bg-black bg-opacity-75 z-[269] flex justify-center items-center">
            <div className="px-12 py-10 bg-white rounded-xl">
              <h1 className="font-medium text-4xl text-center">
                Connect your email address
              </h1>
              <h1 className="text-lg text-center mt-4 text-gray-500">
                Superday requires permission to send emails on your behalf
              </h1>
              <h4 className="text-md text-center mt-4 text-gray-500">
                (Don't worry, we'll never send emails without your permission)
              </h4>
              <div className="flex mt-8 w-full align-center justify-center">
                <GoogleOAuth userEmail={user?.email}></GoogleOAuth>
                <OutlookOAuth userEmail={user?.email} />
              </div>
            </div>
          </div>
        )}

        {(cookie("showUpgradePage") && userSubscription === "0") && (
          <div className="fixed inset-0 bg-black bg-opacity-75 z-[269] flex justify-center items-center">
            <div className="px-12 py-10 bg-white rounded-xl">
              <h1 className="font-medium text-4xl text-center">
                Get ahead of your competition
              </h1>
              <div className="flex mt-8">
                <div className="border border-gray-200 w-[350px] h-auto rounded-lg p-6 mr-8">
                  <h1 className="text-2xl font-medium text-gray-500">
                    Basic plan
                  </h1>
                  <ul className="mt-6 space-y-5 text-gray-400">
                    <li className="flex align-center items-center text-lg">
                      {" "}
                      <FaRegCircleXmark className="mr-3" /> 20 email credits per
                      month
                    </li>
                    <li className="flex align-center items-center text-lg">
                      {" "}
                      <FaRegCircleXmark className="mr-3" /> No automatic sending
                    </li>
                    <li className="flex align-center items-center text-lg">
                      {" "}
                      <FaRegCircleXmark className="mr-3" /> No email schedule
                      send
                    </li>
                  </ul>
                  <button
                    onClick={() =>
                      window.open(
                        "https://buy.stripe.com/fZe4jK2nU95fauQ000",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                    className="mt-8 border border-gray-400 text-gray-400 rounded-full px-8 py-2 hover:bg-black hover:text-white duration-500 ease-in-out"
                  >
                    Start free trial
                  </button>
                </div>
                <div className=" border border-gray-300 bg-[#f8fbff] w-[350px] h-auto rounded-lg p-6">
                  <h1 className="text-2xl font-medium text-black flex items-center">
                    Premium plan
                  </h1>
                  <ul className="mt-6 mb-8 space-y-5 text-black">
                    <li className="flex align-center items-center text-lg">
                      {" "}
                      <MdStars className="mr-2 text-2xl text-green-500" /> 100
                      email credits per month
                    </li>
                    <li className="flex align-center items-center text-lg">
                      {" "}
                      <MdStars className="mr-2 text-2xl text-green-500" />{" "}
                      Automated email sending
                    </li>
                    <li className="flex align-center items-center text-lg">
                      {" "}
                      <MdStars className="mr-2 text-2xl text-green-500" />{" "}
                      Customize schedule send time
                    </li>
                  </ul>
                  <StripeUpgrade
                    priceID={"price_1P08GUGgKK69nhANTPoA4hQ2"}
                    copywriting={"Start for $9.99 / month"}
                  ></StripeUpgrade>
                </div>
              </div>
              <div className="w-full justify-center flex">
                <button
                  onClick={() => {
                    cookie("showUpgradePage", false);
                    window.location.reload();
                  }}
                  className="mt-10 text-gray-400 rounded-full px-8 py-2 hover:bg-black hover:text-white duration-500 ease-in-out"
                >
                  I want to continue as a free user
                </button>
              </div>
            </div>
          </div>
        )}

        <DashboardMenu></DashboardMenu>
        {isLoading ? (
          <div>Loading user data...</div>
        ) : (
          <div className="w-full max-h-[100vh] overflow-y-scroll">
            <DashboardProfile userData={userData} />
            <Approve></Approve>
          </div>
        )}
      </div>
    );
}