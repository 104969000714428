import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { getUserData, updateUserAttribute, addToUserAttribute } from '../../components/awsAuth';
import { useAuth0 } from "@auth0/auth0-react";

const CLIENT_ID = '7dbb355e-0e6a-4756-a249-ddcd0aea8a93';
const TENANT_ID = '28f7ef01-3312-47de-99de-f37228863a5a'; 
const REDIRECT_URI = window.location.origin + '/dashboard'; // Dynamically set the redirect URI

const OutlookOAuthSendEmails = ({ userEmail, fullName, numToSend, emailsToUpdate, selectedEmailType, allEmails }) => {
    let navigate = useNavigate();
    const [state] = useState(uuidv4());
    const [loading, setLoading] = useState(false);

    const onSuccess = (response) => {
        console.log("Login Successful:", response);
        setLoading(true);

        const code = new URLSearchParams(window.location.search).get('code');
        if (!code) {
            console.log("No authorization code found in the response.");
            setLoading(false);
            return;
        }

        const apiGatewayEndpointToken = "https://ylqm1lgeud.execute-api.us-east-1.amazonaws.com/dev/OutlookTokenExchange"; // Update to your actual endpoint
        const apiGatewayEndpointEmail = "https://ylqm1lgeud.execute-api.us-east-1.amazonaws.com/dev/send-emails-sqs"; // Update to your actual endpoint

        (async () => {
            try {
                await updateEmails(emailsToUpdate, selectedEmailType, allEmails);

                const tokenResponse = await fetch(apiGatewayEndpointToken, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        code: code,
                        state: state,
                        redirect_uri: REDIRECT_URI,
                        email: userEmail 
                    })
                });

                if (!tokenResponse.ok) throw new Error('Token exchange failed');
                const tokenData = await tokenResponse.json();
                console.log('Token Data:', tokenData);

                const emailTypes = ['coffee'];

                for (const type of emailTypes) {
                    const emailResponse = await fetch(apiGatewayEndpointEmail, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            email_type: type,
                            full_name: fullName,
                            client_email: userEmail,
                            use_mutex: true
                        })
                    });

                    if (!emailResponse.ok) throw new Error(`Sending ${type} emails failed`);
                    const emailData = await emailResponse.json();
                    console.log(`${type} emails sent successfully:`, emailData);
                }
                setLoading(false);
                window.location.reload();
            } catch (error) {
                console.error('An error occurred:', error);
                setLoading(false);
            }
        })();
    };

    const onFailure = (response) => {
        console.error("Login Failed:", response);
        setLoading(false);
    };

    const updateEmails = async (emailsToUpdate, selectedEmailType, allEmails) => {
        let numConfirmedEmails = 0;

        const selectedEmailsSet = new Set(emailsToUpdate.map(email => email.company_email));

        // Update selected emails to Confirmed
        let updatedEmails = allEmails.map(email => {
            if (email.email_status === 'Sent') {
                // Leave "Sent" emails unchanged
                return email;
            } else if (selectedEmailsSet.has(email.company_email) && email.email_status === 'Unconfirmed') {
                numConfirmedEmails++;
                return {
                    ...email,
                    email_status: 'Confirmed',
                    email_type: selectedEmailType,
                    day_sent: new Date().toISOString()
                };
            } else if (!selectedEmailsSet.has(email.company_email)) {
                return {
                    ...email,
                    email_status: 'Unconfirmed'
                };
            }
            return email;
        });

        await updateUserAttribute(userEmail, 'emails_to_send', updatedEmails);

        // Update number of email sends attempted
        await addToUserAttribute(userEmail, 'email_sends_attempted', 1);

        // Update number of email credits remaining
        await addToUserAttribute(userEmail, 'num_unsent_emails', -numConfirmedEmails);
    };

    return (
        <div>
            {loading ? (
                <p className='text-white px-8 py-2 font-medium text-lg bg-blue-500 rounded-full'>Sending... Please do not refresh.</p>
            ) : (
                <button 
                    className='border-black border bg-blue-500 px-8 py-2 rounded-3xl text-white hover:bg-blue-600 transition-colors duration-500 ease-in-out'
                    onClick={onSuccess}
                >
                    Send Selected ({numToSend})
                </button>
            )}
        </div>
    );
};

export default OutlookOAuthSendEmails;
